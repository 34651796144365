var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('van-search',{attrs:{"placeholder":"请输入搜索发布人"},on:{"search":_vm.onSearch},model:{value:(_vm.createName),callback:function ($$v) {_vm.createName=$$v},expression:"createName"}})],1),_c('van-pull-refresh',{attrs:{"success-text":"加载成功"},on:{"refresh":_vm.onRefresh},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[(!_vm.list || _vm.list.length == 0)?_c('van-empty'):_vm._e(),_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(item){return _c('van-cell-group',{key:item.id,attrs:{"title":item.goodsName +
            '（' +
            item.loadPortName +
            ' 至 ' +
            item.unloadPortName +
            ')'}},[_c('van-divider',{style:({
            color: '#1989fa',
            borderColor: '#1989fa',
            padding: '0 16px',
          })},[_vm._v(" "+_vm._s(item.createDate)+" ")]),_c('van-cell-group',[_c('van-cell',{attrs:{"title-class":"title-cls","title":"货物名称","value":item.goodsName}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"货物重量","value":item.cargoWeight}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"装载港口","value":item.loadPortName}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"卸载港口","value":item.unloadPortName}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"计划装载日期","value":item.planDate}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"浮动天数","value":item.floatingDays}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"价格区间","value":item.priceRange}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"联系人","value":item.contacts}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"手机号","value":item.mobile}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"发布人","value":item.createName || '--'}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"发布人所属公司","value":item.createCompanyName || '--'}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"审核人姓名","value":item.authenName || '--'}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"审核时间","value":item.authenTime || '--'}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"审核状态","value":item.status == 10
                ? '待审核'
                : item.status == 1
                ? '已发布'
                : item.status == 11
                ? '驳回'
                : item.status == 12
                ? '重新审核'
                : item.status == 3
                ? '已结束'
                : '未知'}}),(item.status == 11)?_c('van-cell',{attrs:{"title-class":"title-cls","title":"驳回原因","value":item.rejectReason}}):_vm._e()],1),(_vm.code == 10 || _vm.code == 12)?_c('van-row',{attrs:{"type":"flex","justify":"center"}},[_c('van-col',{attrs:{"span":"8"}},[_c('van-button',{attrs:{"plain":"","hairline":"","type":"warning"},on:{"click":function($event){return _vm.cannelAuthen(item.id)}}},[_vm._v("驳回")])],1),_c('van-col',{attrs:{"span":"8"}},[_c('van-button',{attrs:{"plain":"","hairline":"","type":"primary"},on:{"click":function($event){return _vm.successAuthen(item.id)}}},[_vm._v("通过")])],1)],1):_vm._e()],1)}),1)],1),_c('van-popup',{style:({ height: '10rem', paddingTop: '3rem' }),attrs:{"position":"bottom","get-container":"body","closeable":"","round":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('van-cell-group',[_c('van-field',{attrs:{"rows":"2","autosize":"","type":"textarea","placeholder":"请填写驳回原因"},model:{value:(_vm.cannelVal),callback:function ($$v) {_vm.cannelVal=$$v},expression:"cannelVal"}})],1),_c('van-row',{attrs:{"type":"flex","justify":"center"}},[_c('van-col',{attrs:{"span":"8"}},[_c('van-button',{attrs:{"plain":"","hairline":""},on:{"click":function($event){_vm.show = false}}},[_vm._v("取消")])],1),_c('van-col',{attrs:{"span":"8"}},[_c('van-button',{attrs:{"plain":"","hairline":"","type":"primary"},on:{"click":function($event){return _vm.successCannelAuthen()}}},[_vm._v("确认")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }