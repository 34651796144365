<template>
    <section>

        <div
                ref="bgrefpan"
                class="bg"
                v-if="showpanel"

        >
            <!--            @click="clickOhter"-->
            <div id="condition_panel" class="content-pan">
                <van-nav-bar
                        title="筛选条件"
                        left-text="取消"
                        left-arrow
                        @click-left="showpanel = false"
                />
                <!-- 输入任意文本 -->
                <van-field v-model="shipName" label="船舶名称" placeholder="请输入船名"/>
                <!-- 输入手机号，调起手机号键盘 -->
                <van-field v-model="shipMmsi" type="tel" label="mmsi" placeholder="请输入mmsi"/>
                <!-- 允许输入正整数，调起纯数字键盘 -->
                <van-field
                        readonly
                        :clearable="true"
                        label="注册人"
                        :value="username"
                        placeholder="请选择注册人"
                        @click="showUidPicker = true"
                />
                <van-popup v-model="showUidPicker" round position="bottom">
                    <!--                    :columns="columns"-->
                    <van-picker
                            show-toolbar
                            @cancel="showUidPicker = false"
                            @confirm="onConfirmU"
                    />
                </van-popup>
                <van-field
                        readonly
                        :clearable="true"
                        label="船公司"
                        :value="companyName"
                        placeholder="选择船公司"
                        @click="showcompanyIdPicker = true"
                />
                <!--                :columns="companylist"-->
                <van-popup v-model="showcompanyIdPicker" round position="bottom">
                    <van-picker
                            show-toolbar
                            @cancel="showcompanyIdPicker = false"
                            @confirm="onConfirmC"
                            :columns="companylist"
                    />
                </van-popup>
                <!-- 输入密码 -->
                <div style="position: absolute;bottom: 1rem;width: 80%;">
                    <div>
                        <van-button round style="padding:0 1.5rem;margin:0 5%" type="info"
                                    @click="clearPro()">重置
                        </van-button>
                        <van-button round style="padding:0 1.5rem;margin-right: 5%" type="info" @click="queding()">确定
                        </van-button>
                    </div>

                </div>
            </div>
        </div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <!--            <van-empty v-if="!Shiplist || total == 0"/>-->
            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
            >
                <van-collapse v-model="activeNames" accordion>
                    <div v-for="(item,index) in Shiplist" :key="index"
                         @click="selectShipInfoById(item.id,index,item.mmsi)">
                        <van-collapse-item :title="item.shipEname" :name="index">
                            <van-cell-group>
                                <van-cell
                                        title-class="title-cls"
                                        title="船东"
                                        :value="item.contacts"
                                />
                                <van-cell
                                        title-class="title-cls"
                                        title="联系方式"
                                        :value="item.contactNumber"
                                />
                                <van-cell
                                        title-class="title-cls"
                                        title="吨位"
                                        :value="item.total?item.total:'--'"
                                />
                            </van-cell-group>
                            <van-cell-group v-if="shipdetail">
                                <van-cell
                                        title-class="title-cls"
                                        title="建造完成时间"
                                        :value="shipdetail.shipcdate?shipdetail.shipcdate:'--'"
                                />
                                <van-cell
                                        title-class="title-cls"
                                        title="船舶尺寸（长*宽*深）"
                                        :value="shipdetail.shipLength+'*'+shipdetail.shipWeight+'*'+shipdetail.shipHeight"
                                />
                                <van-cell
                                        title-class="title-cls"
                                        title="舱口尺寸"
                                        :value="shipdetail.hatchLength+'*'+shipdetail.hatchWeight+'*'+shipdetail.hatchHeigth"
                                />
                                <van-cell
                                        title-class="title-cls"
                                        title="仓口形式"
                                        :value="cangtypes[shipdetail.cangtype]"
                                />
                                <van-cell
                                        title-class="title-cls"
                                        title="封舱形式"
                                        :value="hatchtypes[shipdetail.hatchtype]"
                                />
                                <van-cell
                                        title-class="title-cls"
                                        title="视频监控（是否有监控）"
                                        :value="shipdetail.isvideo==1?'是':'否'"
                                />
                                <van-cell
                                        title-class="title-cls"
                                        title="计量方式（是否有水尺）"
                                        :value="shipdetail.isdraft==1?'是':'否'"
                                />
                                <van-cell
                                        title-class="title-cls"
                                        title="主机"
                                        :value="shipdetail.shipzjx?shipdetail.shipzjx:'--'"
                                />
                                <van-cell
                                        title-class="title-cls"
                                        title="评级分数"
                                        :value="shipdetail.standScore?shipdetail.standScore:'0'"
                                />
                                <van-cell
                                        title-class="title-cls"
                                        title="船舶等级"
                                        :value="shipdetail.grade?shipdetail.grade:'--'"
                                />

                                <van-cell
                                        v-for="(res,index) in scoreList"
                                        :key="res.id"
                                        title-class="title-cls"
                                        :title="'最近'+(Number(index)+1)+'次物流评分'"
                                        :value="res.score"
                                />

                                <van-cell
                                        title-class="title-cls"
                                        title="服务质量满意度"
                                        value="--"
                                />
                                <van-cell
                                        title-class="title-cls"
                                        title="船舶抵港及时率满意度"
                                        value="--"
                                />
                                <van-cell
                                        title-class="title-cls"
                                        title="数量差异率满意度"
                                        value="--"
                                />
                                <van-cell
                                        title-class="title-cls"
                                        title="平台内运输航次"
                                        value="**"
                                />
                                <van-cell
                                        title-class="title-cls"
                                        title="经常航线"
                                        value="**"
                                />
                            </van-cell-group>
                        </van-collapse-item>
                    </div>
                    <!--                    <van-collapse-item title="标题1" name="1">内容</van-collapse-item>-->
                    <!--                    <van-collapse-item title="标题2" name="2">内容</van-collapse-item>-->
                    <!--                    <van-collapse-item title="标题3" name="3">内容</van-collapse-item>-->
                </van-collapse>
            </van-list>
        </van-pull-refresh>
    </section>
</template>

<script>
    import {
        PullRefresh,
        List,
        Collapse,
        CollapseItem,
        CellGroup,
        Cell,
        Button,
        Field,
        Toast, Picker, Popup, NavBar,
        // Empty,
    } from 'vant'
    import ship from '../api/ship'
    import company from '../api/company'

    export default {
        name: "ShipBase",
        data() {
            return {
                isSelect: true,
                showpanel: false,
                pageNo: 1,
                pageSize: 20,
                minWeight: null,
                maxWeight: null,
                shipMmsi: null,
                shipName: null,
                companyId: null,
                uid: null,
                loading: false,
                finished: false,
                refreshing: false,
                total: 0,
                Shiplist: [],
                shipdetail: {},
                activeNames: ['1'],
                activeName: '1',
                pageInfo: {},
                cangtypes: ['满梁', '空梁', '通舱'], //舱口形式
                hatchtypes: ['雨布封舱', '棚架封舱', '舱盖封舱'], //封舱形式
                index: 0,
                showcompanyIdPicker: false,
                showUidPicker: false,
                companylist: [],
                companyName: '',
                username: '',
                scoreList: []
            };
        },
        components: {
            VanPullRefresh: PullRefresh,
            VanList: List,
            VanCollapse: Collapse,
            VanCollapseItem: CollapseItem,
            VanCellGroup: CellGroup,
            VanCell: Cell,
            VanButton: Button,
            VanField: Field,
            [Picker.name]: Picker,
            VanPopup: Popup,
            VanNavBar: NavBar,
            // VanEmpty: Empty,
        },
        created() {
            this.$store.commit('SET_APPINFO', {
                navTitle: '船舶基础信息',
                rightText: '筛选',
                rightClick: this.closepanel,
            })
            this.selectShipInfo();
            this.selectCompanyList();
        },
        methods: {
            queding() {
                this.pageNo = 1;
                this.Shiplist = []
                this.selectShipInfo();
                this.showpanel = false;
            },
            clearPro() {
                this.minWeight = null
                this.maxWeight = null
                this.shipMmsi = null
                this.shipName = null
                this.companyId = null
                this.uid = null
                this.username = null
                this.companyName = null
                this.pageNo = 1
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.shipdetail = {}
                this.Shiplist = []
                this.pageNo = 1
                // this.clearPro();
                this.selectShipInfo();
            },
            onLoad() {
                // 异步更新数据
                // setTimeout 仅做示例，真实场景中一般为 ajax 请求
                this.pageNo++;
                this.selectShipInfo();
            },
            closepanel() {
                this.showpanel = true;
            },
            clickOhter() {
                document.addEventListener("click", event => {
                    let cDom = document.getElementById("condition_panel");
                    let tDom = event.target;
                    if (cDom) {
                        if (cDom == tDom || cDom.contains(tDom)) {
                            //**
                        } else {
                            // cDom.style.display = "none";
                            this.showpanel = false;
                        }
                    }

                })
            },
            selectShipInfo() {
                ship.seletShipPages(this.pageNo, this.pageSize, this.shipMmsi, this.shipName, this.companyId, this.uid, this.minWeight, this.maxWeight).then(res => {
                    // if (res.resultCode === '0') {
                    this.total = res.total;
                    this.Shiplist = this.Shiplist.concat(res.list);
                    this.loading = false
                    this.refreshing = false
                    if (this.pageSize * this.pageNo >= this.total) {
                        this.finished = true;
                    }

                    // }
                })
            },
            selectShipInfoById(id, index, mmsi) {
                console.log(id, index, mmsi)
                if (this.index == index) {
                    return
                }
                this.index = index;
                ship.getShipById(id).then(res => {
                    this.shipdetail = res;
                });
                this.scoreList = []
                ship.selectListLastVoyageNumber(mmsi).then(res => {
                    let i = 1;
                    res.forEach(el=>{
                        if(i<=3){
                            this.scoreList.push(el);
                            i++
                        }
                    })
                });
            },
            onConfirmU(value, index) {
                Toast(`当前值：${value}, 当前索引：${index}`);
                this.userName = value;
                this.companyId = 1;
            },
            onConfirmC(value, index) {
                Toast(`当前值：${value}, 当前索引：${index}`);
                this.companyName = value.text;
                this.companyId = value.value;
                this.showcompanyIdPicker = false;
                console.log(`当前值：${value.text}${value.value}, 当前索引：${index}`)
            },
            onChange(picker, value, index) {
                Toast(`当前值：${value}, 当前索引：${index}`);
            },
            onCancel() {
                Toast('取消');
            },
            selectCompanyList() {
                this.companylist = []
                this.pageNo = 1
                company.list('3', this.pageNo, 150).then((res) => {
                    if (res) {
                        for (let data of res) {
                            let marker = {
                                text: data.companyName,
                                value: data.id,
                            };
                            this.companylist.push(marker);
                        }
                    }
                })
            }
        },
    }
</script>
<style scoped>
    .bg {
        /*padding-left: 10%;*/
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: #00000063;
        top: 0;
        right: 0;

        bottom: 0;
        z-index: 35000;
        overflow: auto;
        /* margin-bottom:   env(safe-area-inset-bottom); */
        transition: 0.3s width linear;
        animation: 0.3s rainbow;
    }

    @keyframes rainbow {
        0% {
            width: 0;
        }
        100% {
            width: 100%;
        }
    }

    @keyframes lcolse {
        0% {
            width: 100%;
        }
        100% {
            width: 0;
        }
    }

    @keyframes rainbowPan {
        0% {
            width: 0;
        }
        100% {
            width: 22.9rem;
        }
    }

    .content-pan {
        /* position: fixed;
        top: 0;
        right: 0; */
        float: right;
        width: 80vw;
        /* background: #fff; */
        background: transparent;
        /* background: rgba(249, 249, 249, 1); */
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        min-height: 100%;
        background: #fff;
        transition: 0.3s width linear;
        animation: 0.3s rainbowPan;
    }


</style>
<style>
    .title-cls {
        text-align: left;
    }

    .vpan {
        min-height: 200px;
    }
</style>>