//import axios from "axios";
const axios= (window as any).axios
import { Toast } from "vant";

export const TPL_PARAMS = "_tpl_md_table";
export const TPL_FORM_DATA = "_tpl_md_value";
export const TPL_TABLENAME = "_tpl_tname";
export const TPL_OBJ_ID = "_tpl_obj_id";
export const TPL_PARENT_OBJ_ID = "_tpl_parent_obj_id";
export const TPL_OBJ_BIND_ID = "_tpl_obj_bind_id";
export const TPL_TABLE_PARAMS = "_tpl_md_table_params";
export const TPL_TABLE_OTHER_PARAMS = "_tpl_md_table_other_params";
export const TPL_TABLE_TYPE = "_tpl_md_table_type";
export const TPL_TABLE_TPLID = "_tpl_md_table_tplid";
export const TPL_SHIPID_PARAMS = "_tpl_shipid_params";
export const TPL_FROM_OWN_DATA = "_tpl_own_data"; // 自定义属性集合

const gtemplate: any = {
  getDetail(typetpl: string, objId: string) {
    const url = "/tpl/tplValueByStandbyId";
    const data = {
      [TPL_PARAMS]: {
        [TPL_TABLENAME]: typetpl,
        [TPL_OBJ_ID]: objId
      }
    };
    return axios.post(url, data).then((res: any) => {
      if (res.data && res.data.resultCode === "0") {
        return Promise.resolve(res.data.data);
      } else {
        Toast(res.data ? res.data.resultMsg : "网络异常");
        return Promise.reject(res.data);
      }
    });
  }
};
export default gtemplate;
