<template>
  <div class="about">
    <van-tabs
      v-model="active"
      sticky
      animated
      swipeable
      color="#4CD5A6"
      @change="tabChange"
    >
      <van-tab title="待审核" name="1">
        <real-name-chk-list ref="child1" :code="active"></real-name-chk-list>
      </van-tab>
      <van-tab title="审核通过" name="3"
        ><real-name-chk-list ref="child3" :code="active"></real-name-chk-list
      ></van-tab>
      <van-tab title="未通过" name="2"
        ><real-name-chk-list ref="child2" :code="active"></real-name-chk-list
      ></van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { Tab, Tabs } from 'vant'
import RealNameChkList from '../components/RealNameChkList.vue'
export default {
  name: 'RealNameChk',
  data() {
    return {
      active: 1,
    }
  },
  created() {
    this.$store.commit('SET_APPINFO', { navTitle: '实名认证审核' })
    this.loadRouter()
  },
  components: {
    VanTab: Tab,
    VanTabs: Tabs,
    RealNameChkList,
  },
  methods: {
    loadRouter() {
      console.log(this.$router.history)
    },
    tabChange(name, title) {
      if (name == 1 && this.$refs.child1) {
        this.$refs.child1.onRefresh(this.active)
      } else if (name == 2 && this.$refs.child2) {
        this.$refs.child2.onRefresh(this.active)
      } else if (name == 3 && this.$refs.child3) {
        this.$refs.child3.onRefresh(this.active)
      }
    },
  },
}
</script>
