//import axios from "axios";
const axios= (window as any).axios
import { Toast } from "vant";
const realname: any = {
  authenList(cidStatus: BigInteger, pageNum = 0, pageSize = 10, realName: '') {
    const url = "/lipUser/manageList";
    const data = {
      cidStatus,
      pageNum,
      pageSize,
      realName
    };
    return axios.post(url, data).then((res: any) => {
      if (res.data && res.status === 200) {
        return Promise.resolve(res.data.list);
      } else {
        Toast(res.data ? res.data.resultMsg : "网络异常");
        return Promise.reject(res.data);
      }
    });
  },
  authenExamine(
    id: BigInteger,
    status: string,
    statusExplain = "",
    createBy = ""
  ) {
    const url = "/realnameauthen/authenExamine";
    const data = {
      id,
      status,
      statusExplain,
      createBy
    };
    return axios.post(url, data).then((res: any) => {
      if (res.data && res.data.resultCode === "0") {
        return Promise.resolve(res.data.data);
      } else {
        Toast(res.data ? res.data.resultMsg : "网络异常");
        return Promise.reject(res.data);
      }
    });
  },
  getAuthenDetail(userId: string) {
    const url = "/authenaudit/findLipUserCidByRid";
    const data = { userId };
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === "0") {
        return Promise.resolve(res.data.data);
      } else {
        Toast(res.data ? res.data.resultMsg : "网络异常");
        return Promise.reject(res.data);
      }
    });
  }
};
export default realname;
