var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-pull-refresh',{attrs:{"success-text":"加载成功"},on:{"refresh":_vm.onRefresh},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[(!_vm.list || _vm.list.length == 0)?_c('van-empty'):_vm._e(),_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('van-collapse',{staticClass:"vpan",model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},_vm._l((_vm.list),function(item,index){return _c('van-collapse-item',{key:item.id,attrs:{"title":item.standardizationName,"name":index + 1 + ''}},[_c('van-divider',{style:({
              color: '#1989fa',
              borderColor: '#1989fa',
              padding: '0 16px'
            })},[_vm._v(_vm._s(item.createTime))]),_c('van-cell-group',[_c('van-cell',{attrs:{"title-class":"title-cls","title":"项目名称","value":item.standardizationName}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"序号","value":item.sort}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"项目名称","value":item.standardizationName}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"考核标准","value":item.assessmentStandard}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"类别","value":item.groupName}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"审核人姓名","value":item.authenName}}),_c('van-cell',{attrs:{"title-class":"title-cls","title":"审核时间","value":item.authenTime}}),(item.spare2 == '1' && item.status == '1')?_c('van-cell',{attrs:{"title-class":"title-cls","title":"评分","value":"--"}}):_vm._e(),(item.spare2 == '1' && item.status == '2')?_c('van-cell',{attrs:{"title-class":"title-cls","title":"评分","value":"未达标"}}):_vm._e(),(item.spare2 == '1' && item.status == '3')?_c('van-cell',{attrs:{"title-class":"title-cls","title":"评分","value":"达标"}}):_vm._e(),(item.spare2 == '2' && item.status == '1')?_c('van-cell',{attrs:{"title-class":"title-cls","title":"评分","value":"--"}}):_vm._e(),(item.spare2 == '2' && item.status == '2')?_c('van-cell',{attrs:{"title-class":"title-cls","title":"评分","value":item.score}}):_vm._e(),(item.spare2 == '2' && item.status == '3')?_c('van-cell',{attrs:{"title-class":"title-cls","title":"评分","value":item.score}}):_vm._e(),_c('van-cell',{attrs:{"title-class":"title-cls","title":"审核状态","value":item.status == 1
                  ? '待审核'
                  : item.status == 3
                  ? '审核通过'
                  : item.status == 2
                  ? '驳回'
                  : '未知'}})],1),(_vm.code == 1)?_c('van-row',{attrs:{"type":"flex","justify":"center"}},[_c('van-col',{attrs:{"span":"8"}},[_c('van-button',{attrs:{"plain":"","hairline":"","type":"warning"},on:{"click":function($event){return _vm.goStandDetail(
                    item.objId,
                    _vm.code,
                    item.spare2,
                    _vm.shipId,
                    item.standardizationName
                  )}}},[_vm._v("去审核")])],1)],1):_vm._e(),(_vm.code == 3 || _vm.code == 2)?_c('van-row',{attrs:{"type":"flex","justify":"center"}},[_c('van-col',{attrs:{"span":"8"}},[_c('van-button',{attrs:{"plain":"","hairline":"","type":"warning"},on:{"click":function($event){return _vm.goStandDetail(
                    item.objId,
                    _vm.code,
                    item.spare2,
                    _vm.shipId,
                    item.standardizationName
                  )}}},[_vm._v("审核详情")])],1)],1):_vm._e()],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }