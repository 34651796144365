




















































































































































































import {
  Empty,
  PullRefresh,
  Button,
  List,
  Cell,
  Divider,
  CellGroup,
  Field,
  Col,
  Row,
  Image as VanImage,
  ImagePreview,
  Popup,
  Search,
  Toast,
  Notify,
  Collapse,
  CollapseItem,
} from 'vant'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ship from '../api/ship'

@Component({
  name: 'ShipChkList',
  components: {
    VanField: Field,
    VanEmpty: Empty,
    VanSearch: Search,
    VanPullRefresh: PullRefresh,
    VanList: List,
    VanCell: Cell,
    VanDivider: Divider,
    VanCellGroup: CellGroup,
    VanImage: VanImage,
    VanButton: Button,
    VanCol: Col,
    VanRow: Row,
    VanPopup: Popup,
    VanCollapse: Collapse,
    VanCollapseItem: CollapseItem,
  },
})
export default class ShipChkList extends Vue {
  @Prop() private code!: number
  private isLoading = false
  private loading = false
  private finished = false
  private show = false
  private cannelVal = ''
  private shipName = ''
  private list = [] as any
  private activeNames = ['1']
  recordId!: string
  pageNo = 0
  private onLoad(): void {
    this.pageNo++
    this.showCode(this.code)
  }
  private onSearch() {
    this.onRefresh(0)
  }
  private cannelAuthen(id: string): void {
    this.recordId = id
    this.cannelVal = ''
    // 审核拒绝
    // 填写拒绝理由
    this.show = true
  }
  private successCannelAuthen(): void {
    if (this.cannelVal) {
      Toast.loading({
        message: '加载中...',
        duration: 0,
        forbidClick: true,
      })
      ship
        .shipPlateExamine(this.recordId, '11', this.cannelVal)
        .then((res: any) => {
          Toast.clear()
          this.show = false
          this.onRefresh(this.code)
        })
    } else {
      // 未填写拒绝理由
      Toast.fail('未填写驳回理由')
    }
  }
  private successAuthen(id: BigInteger): void {
    // 审核通过
    Toast.loading({
      message: '加载中...',
      duration: 0,
      forbidClick: true,
    })
    ship.shipPlateExamine(id, '1', '').then((res: any) => {
      this.onRefresh(this.code)
      Toast.clear()
    })
  }
  private showCode(code: number): void {
    ship
      .shipPlateList(code, this.pageNo, 20, this.shipName)
      .then((res: any) => {
        this.isLoading = false
        this.loading = false
        if (!res || !res.list || res.list.length == 0) {
          this.finished = true
        } else {
          this.list = this.list.concat(res.list)
        }
      })
  }
  onRefresh(code: number): void {
    this.pageNo = 1
    this.finished = false
    this.list = []
    this.showCode(code || this.code)
  }
}
