import { render, staticRenderFns } from "./RealNameChkList.vue?vue&type=template&id=e32d8db4&scoped=true&"
import script from "./RealNameChkList.vue?vue&type=script&lang=ts&"
export * from "./RealNameChkList.vue?vue&type=script&lang=ts&"
import style0 from "./RealNameChkList.vue?vue&type=style&index=0&id=e32d8db4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e32d8db4",
  null
  
)

export default component.exports