<template>
  <div class="about">
    <van-tabs
      v-model="active"
      sticky
      animated
      swipeable
      color="#4CD5A6"
      @change="tabChange"
    >
      <van-tab title="审核中" name="1">
        <ship-chk-list ref="child1" :code="active"></ship-chk-list>
      </van-tab>
      <van-tab title="审核通过" name="3"
        ><ship-chk-list ref="child3" :code="active"></ship-chk-list
      ></van-tab>
      <van-tab title="审核驳回" name="2"
        ><ship-chk-list ref="child2" :code="active"></ship-chk-list
      ></van-tab>
      <van-tab title="取消认证" name="4"
        ><ship-chk-list ref="child4" :code="active"></ship-chk-list
      ></van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { Tab, Tabs } from "vant";
import ShipChkList from "../components/ShipChkList.vue";
export default {
  name: "ShipChk",
  data() {
    return {
      active: 1
    };
  },
  created() {
    this.$store.commit("SET_APPINFO", { navTitle: "船舶审核" });
    this.loadRouter();
  },
  components: {
    VanTab: Tab,
    VanTabs: Tabs,
    ShipChkList
  },
  methods: {
    loadRouter() {
      console.log(this.$router.history);
    },
    tabChange(name, title) {
      if (name == 1 && this.$refs.child1) {
        this.$refs.child1.onRefresh(this.active);
      } else if (name == 2 && this.$refs.child2) {
        this.$refs.child2.onRefresh(this.active);
      } else if (name == 3 && this.$refs.child3) {
        this.$refs.child3.onRefresh(this.active);
      } else if (name == 4 && this.$refs.child4) {
        this.$refs.child4.onRefresh(this.active);
      }
    }
  }
};
</script>
