


































































































































































import {
  Empty,
  PullRefresh,
  Button,
  List,
  Cell,
  Divider,
  CellGroup,
  Field,
  Col,
  Row,
  Image as VanImage,
  ImagePreview,
  Popup,
  Toast,
  Notify,
  Collapse,
  CollapseItem
} from "vant";
import { Component, Prop, Vue } from "vue-property-decorator";
import ship from "../api/ship";

@Component({
  name: "ShipStandChkList",
  components: {
    VanField: Field,
    VanEmpty: Empty,
    VanPullRefresh: PullRefresh,
    VanList: List,
    VanCell: Cell,
    VanDivider: Divider,
    VanCellGroup: CellGroup,
    VanImage: VanImage,
    VanButton: Button,
    VanCol: Col,
    VanRow: Row,
    VanPopup: Popup,
    VanCollapse: Collapse,
    VanCollapseItem: CollapseItem
  }
})
export default class ShipStandChkList extends Vue {
  @Prop() private code!: BigInteger;
  @Prop() private shipId!: string;
  private isLoading = false;
  private loading = false;
  private finished = false;
  private list = [] as any;
  private activeNames = ["1"];
  private onLoad(): void {
    this.showCode(this.code);
  }
  private goStandDetail(
    id: string,
    status: string,
    stype: string,
    shipId: string,
    itemName: string
  ): void {
    this.$router.push({
      path: "/shipRzDetail",
      query: {
        objId: id,
        status: status,
        stype: stype,
        shipId: shipId,
        itemName: itemName
      }
    });
  }
  private showCode(code: BigInteger): void {
    ship.queryStandardItemsRz(this.shipId, code).then((res: any) => {
      this.list = res;
      this.isLoading = false;
      this.loading = false;
      this.finished = true;
    });
  }
  onRefresh(code: BigInteger): void {
    this.finished = false;
    this.list = [];
    this.showCode(code|| this.code);
  }
}
