<template>
  <div class="about">
    <van-tabs
      v-model="active"
      sticky
      animated
      swipeable
      color="#4CD5A6"
      @change="tabChange"
    >
      <van-tab title="待审核" name="1">
        <com-chk-list :code="active"></com-chk-list>
      </van-tab>
      <van-tab title="审核通过" name="3"
        ><com-chk-list :code="active"></com-chk-list
      ></van-tab>
      <van-tab title="未通过" name="2"
        ><com-chk-list :code="active"></com-chk-list
      ></van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { Tab, Tabs } from 'vant'
import ComChkList from '../components/CompanyChkList.vue'
export default {
  name: 'About',
  data() {
    return {
      active: 0,
    }
  },
  created() {
    this.$store.commit('SET_APPINFO', { navTitle: '审核企业' })
  },
  components: {
    VanTab: Tab,
    VanTabs: Tabs,
    ComChkList,
  },
  methods: {
    tabChange(title, name) {
      // console.log(title, name)
    },
  },
}
</script>
