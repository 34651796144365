


























































































































































































































import {
  Empty,
  PullRefresh,
  Button,
  List,
  Cell,
  Divider,
  CellGroup,
  Field,
  Col,
  Row,
  Image as VanImage,
  ImagePreview,
  Popup,
  Toast,
  Collapse,
  CollapseItem,
  Search
} from 'vant'
import { Component, Prop, Vue } from 'vue-property-decorator'
import realname from '../api/realname'

@Component({
  name: 'RealNameChkList',
  components: {
    VanField: Field,
    VanEmpty: Empty,
    VanPullRefresh: PullRefresh,
    VanList: List,
    VanCell: Cell,
    VanDivider: Divider,
    VanCellGroup: CellGroup,
    VanImage: VanImage,
    VanButton: Button,
    VanCol: Col,
    VanRow: Row,
    VanPopup: Popup,
    VanCollapse: Collapse,
    VanCollapseItem: CollapseItem,
    VanSearch: Search
  },
})
export default class UserChkList extends Vue {
  @Prop() private code!: BigInteger
  private isLoading = false
  private loading = false
  private finished = false
  private show = false
  private cannelVal = ''
  private list = [] as any
  private activeNames = ['1']
  private realName = ""
  pageNo = 0
  realNameId!: BigInteger
  companyindex = 0
  private onLoad(): void {
    this.pageNo++
    this.showCode(this.code, this.realName)
  }
  private cannelAuthen(id: BigInteger, index: number): void {
    this.realNameId = id
    this.cannelVal = ''
    this.companyindex = index
    // 审核拒绝
    // 填写拒绝理由
    this.show = true
  }
  private successCannelAuthen(): void {
    if (this.cannelVal) {
      Toast.loading({
        message: '加载中...',
        duration: 0,
        forbidClick: true,
      })
      realname
        .authenExamine(this.realNameId, '2', this.cannelVal)
        .then((res: any) => {
          Toast.clear()
          this.show = false
          this.onRefresh(this.code, this.realName)
        })
    } else {
      // 未填写拒绝理由
      Toast.fail('未填写拒绝理由')
    }
  }
  private successAuthen(id: BigInteger, index: number): void {
    // 审核通过
    this.companyindex = index
    Toast.loading({
      message: '加载中...',
      duration: 0,
      forbidClick: true,
    })
    realname
      .authenExamine(id, '3', '', this.list[index].createBy)
      .then((res: any) => {
        this.onRefresh(this.code, this.realName)
        Toast.clear()
      })
  }
  private showImg(list: Array<string>, start = 0) {
    if (typeof list === 'string') {
      list = [list]
    }
    ImagePreview({
      images: list,
      startPosition: start,
      onClose() {
        // do something
      },
    })
  }
  private showCode(code: BigInteger, realName: string): void {
    realname.authenList(code, this.pageNo, 20).then((res: any) => {
      if (null !== res) {
        this.list = this.list.concat(res)
      }
      this.isLoading = false
      this.loading = false
      if (!res || res.length == 0) {
        this.finished = true
      }
    })
  }
  private onSearch() {
    this.onRefresh(this.code, this.realName);
  }
  onRefresh(code: BigInteger, realName: string): void {
    this.pageNo = 1
    this.finished = false
    this.list = []
    this.showCode(code || this.code, realName)
  }
}
