
































































































































































































































import {
  Empty,
  PullRefresh,
  Button,
  List,
  Cell,
  Divider,
  CellGroup,
  Search,
  Field,
  Col,
  Row,
  Image as VanImage,
  ImagePreview,
  Popup,
  Toast,
  Notify,
  RadioGroup,
  Radio,
  Collapse,
  CollapseItem,
  Loading,
  Dialog,
} from 'vant'
import { Component, Prop, Vue } from 'vue-property-decorator'
import company from '../api/company'
import realname from '../api/realname'
@Component({
  name: 'CompanyChkList',
  components: {
    VanField: Field,
    VanEmpty: Empty,
    VanSearch: Search,
    VanPullRefresh: PullRefresh,
    VanList: List,
    VanCell: Cell,
    VanDivider: Divider,
    VanCellGroup: CellGroup,
    VanImage: VanImage,
    VanButton: Button,
    VanCol: Col,
    VanRow: Row,
    VanPopup: Popup,
    VanCollapse: Collapse,
    VanCollapseItem: CollapseItem,
    VanLoading: Loading,
    VanRadioGroup: RadioGroup,
    VanRadio: Radio,
  },
})
export default class CompanyChkList extends Vue {
  @Prop() private code!: string
  private isLoading = false
  private loading = false
  private finished = false
  private show = false
  private cannelVal = ''
  private companyName = ''
  private list = [] as any
  private applys = {} as any
  private activeNames = ['1']
  pageNo = 1
  companyId = ''
  companyindex = 0
  private param2 = ''
  private created(): void {
    this.showCode()
  }
  private onSearch() {
    this.onRefresh()
  }
  private onLoad(): void {
    this.pageNo++
    this.showCode()
  }
  private cannelCom(id: string, index: number): void {
    this.companyId = id
    this.cannelVal = ''
    this.companyindex = index
    // 审核拒绝
    // 填写拒绝理由
    this.show = true
  }
  private successCannelCom(): void {
    if (this.cannelVal) {
      Toast.loading({
        message: '加载中...',
        duration: 0,
        forbidClick: true,
      })
      company
        .companyStatus(this.companyId, '2', this.cannelVal)
        .then((res: any) => {
          Toast.clear()
          this.show = false
          if (res) {
            this.hideItem(this.companyindex)
          }
        })
    } else {
      // 未填写拒绝理由
      Toast.fail('未填写拒绝理由')
    }
  }
  private collChange(e: any): void {
    if (e && e.length > 0) {
      // 循环加载
      for (let i = 0; i < e.length; i++) {
        const index = Number(e[i]) - 1
        const itid = this.list[index].createBy
        if (this.applys[itid] && Object.keys(this.applys[itid]).length) {
          continue
        } else {
          this.$set(this.applys, itid, { loading: false, list: [] })
          realname.getAuthenDetail(itid).then((res: any) => {
            this.applys[itid] = res
          })
          break
        }
      }
    }
  }
  private successCom(id: string, index: number): void {
    // 审核通过
    this.companyindex = index
    Toast.loading({
      message: '加载中...',
      duration: 0,
      forbidClick: true,
    })
    if (!this.applys[this.list[index].createBy]) {
      Toast.clear()
      Notify({ type: 'warning', message: '申请人未进行实名认证' })
      return
    }
    if (this.applys && this.applys[this.list[index].createBy].status != 3) {
      Toast.clear()
      Notify({ type: 'warning', message: '申请人实名认证未通过' })
      return
    }
    company
      .companyStatus(id, '3', '', this.list[index].createBy)
      .then((res: any) => {
        if (res) {
          this.hideItem(this.companyindex)
          Toast.clear()
        } else {
          Toast.fail('重名企业或统一社会信用代码已存在')
        }
      })
  }
  private hideItem(index: number) {
    this.list.splice(index, 1)
  }
  private showImg(list: Array<string>, start = 0) {
    if (typeof list === 'string') {
      list = [list]
    }
    ImagePreview({
      images: list,
      startPosition: start,
      onClose() {
        // do something
      },
    })
  }
  private showCode(): void {
    if (this.code == null || this.code == undefined || this.code.trim() == "") {
      this.param2 = "2"
    } else {
      this.param2 = ""
    }
    company
      .list(this.code, this.pageNo, 5, this.companyName, this.param2)
      .then((res: any) => {
        if (null !== res && null !== res.list) {
          this.list = this.list.concat(res.list)
          this.collChange(this.activeNames)
        }
        this.isLoading = false
        this.loading = false
        if (!res || res.length == 0) {
          this.finished = true
        }
      })
  }
  private onRefresh(): void {
    this.pageNo = 1
    this.finished = false
    this.list = []
    this.showCode()
  }
  private updateCompany(id: string, showFlag: string): void {
      Toast.loading({
        message: '加载中...',
        duration: 0,
        forbidClick: true,
      })
      company
        .updateCompany(id, showFlag)
        .then((res: any) => {
          Toast.clear()
        })
  }
}
