<template>
  <div class="about">
    <van-tabs
      v-model="active"
      sticky
      animated
      swipeable
      color="#4CD5A6"
      @change="tabChange"
    >
      <van-tab title="审核中" name="1">
        <ship-rz-chk-list
          ref="child1"
          :shipId="shipId"
          :code="active"
        ></ship-rz-chk-list>
      </van-tab>
      <van-tab title="审核通过" name="3"
        ><ship-rz-chk-list
          ref="child3"
          :shipId="shipId"
          :code="active"
        ></ship-rz-chk-list
      ></van-tab>
      <van-tab title="审核驳回" name="2"
        ><ship-rz-chk-list
          ref="child2"
          :shipId="shipId"
          :code="active"
        ></ship-rz-chk-list
      ></van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { Tab, Tabs } from "vant";
import ShipRzChkList from "../components/ShipRzChkList.vue";
export default {
  name: "ShipRzChk",
  data() {
    return {
      active: 1,
      shipId: this.$route.query.shipId
    };
  },
  created() {
    this.$store.commit("SET_APPINFO", { navTitle: "船舶认证信息" });
    this.loadRouter();
  },
  components: {
    VanTab: Tab,
    VanTabs: Tabs,
    ShipRzChkList
  },
  methods: {
    loadRouter() {
      console.log(this.$router.history);
    },
    tabChange(name, title) {
      if (name == 1 && this.$refs.child1) {
        this.$refs.child1.onRefresh(this.active);
      } else if (name == 2 && this.$refs.child2) {
        this.$refs.child2.onRefresh(this.active);
      } else if (name == 3 && this.$refs.child3) {
        this.$refs.child3.onRefresh(this.active);
      }
    }
  }
};
</script>
