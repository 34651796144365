//import axios from "axios";
const axios = (window as any).axios
import {Toast} from "vant";

const ship: any = {
    shipPagesByStatus(status: BigInteger, pageNo = 0, pageSize = 10, shipName = undefined) {
        const url = "/shipstandard/queryShipStandardPage";
        const data = {
            status,
            pageNo,
            pageSize,
            shipName
        };
        return axios.get(url, {params: data}).then((res: any) => {
            if (res.data && res.data.resultCode === "0") {
                return Promise.resolve(res.data.page.list);
            } else {
                Toast(res.data ? res.data.resultMsg : "网络异常");
                return Promise.reject(res.data);
            }
        });
    },
    authenList(status: BigInteger, pageNo = 0, pageSize = 10, shipName = undefined) {
        const url = "/ship/authenList";
        const data = {
            status,
            pageNo,
            pageSize,
            shipName
        };
        return axios.get(url, {params: data}).then((res: any) => {
            if (res.data && res.data.resultCode === "0") {
                return Promise.resolve(res.data.data);
            } else {
                Toast(res.data ? res.data.resultMsg : "网络异常");
                return Promise.reject(res.data);
            }
        });
    },
    authenExamine(
        shipId: BigInteger,
        status: string,
        rejectReason = "",
        createBy = ""
    ) {
        const url = "/ship/authenExamine";
        const data = {
            shipId,
            status,
            rejectReason,
            createBy
        };
        return axios.get(url, {params: data}).then((res: any) => {
            if (res.data && res.data.resultCode === "0") {
                return Promise.resolve(res.data.data);
            } else {
                Toast(res.data ? res.data.resultMsg : "网络异常");
                return Promise.reject(res.data);
            }
        });
    },
    getSameShip(shipId: BigInteger) {
        const url = "/ship/getSameShip";
        const data = {
            shipId
        };
        return axios.get(url, {params: data}).then((res: any) => {
            if (res.data && res.data.resultCode === "0") {
                return Promise.resolve(res.data.data);
            } else {
                Toast(res.data ? res.data.resultMsg : "网络异常");
                return Promise.reject(res.data);
            }
        });
    },
    queryStandardItems(shipId: string, status: BigInteger) {
        const url = "/ship/queryStandardItems";
        const data = {
            shipId,
            status
        };
        return axios.get(url, {params: data}).then((res: any) => {
            if (res.data && res.data.resultCode === "0") {
                return Promise.resolve(res.data.data);
            } else {
                Toast(res.data ? res.data.resultMsg : "网络异常");
                return Promise.reject(res.data);
            }
        });
    },
    queryStandardItemsRz(shipId: string, status: BigInteger) {
        const url = "/ship/queryStandardItemsRz";
        const data = {
            shipId,
            status
        };
        return axios.get(url, {params: data}).then((res: any) => {
            if (res.data && res.data.resultCode === "0") {
                return Promise.resolve(res.data.data);
            } else {
                Toast(res.data ? res.data.resultMsg : "网络异常");
                return Promise.reject(res.data);
            }
        });
    },
    addShipStandard(
        id: string,
        status: string,
        content: string,
        shipId: string,
        scope: string
    ) {
        const data = {
            id: id,
            status: status,
            content: content,
            shipId: shipId,
            scope: scope
        };
        const url = "/shipstandard/addShipStandard";
        return axios.get(url, {params: data}).then((res: any) => {
            if (res.data && res.data.resultCode === "0") {
                return Promise.resolve(res.data.data);
            } else {
                Toast(res.data ? res.data.resultMsg : "网络异常");
                return Promise.reject(res.data);
            }
        });
    },
    shipPlateList(status: BigInteger, pageNum = 0, pageSize = 10, shipName = undefined) {
        const url = "/shipplate/getShipPlateList";
        const data = {
            status,
            pageNum,
            pageSize,
            shipName
        };
        return axios.get(url, {params: data}).then((res: any) => {
            if (res.data && res.data.resultCode === "0") {
                return Promise.resolve(res.data.page);
            } else {
                Toast(res.data ? res.data.resultMsg : "网络异常");
                return Promise.reject(res.data);
            }
        });
    },
    shipPlateExamine(id: string, examinetype = "", reason = "") {
        const url = "/shipplate/passOrReject";
        const data = {id, examinetype, reason};
        return axios.get(url, {params: data}).then((res: any) => {
            if (res.data && res.data.resultCode === "0") {
                return Promise.resolve(res.data.data);
            } else {
                Toast(res.data ? res.data.resultMsg : "网络异常");
                return Promise.reject(res.data);
            }
        });
    },
    plateList(status: BigInteger, pageNum = 0, pageSize = 10, createName = '') {
        const url = "/shipplate/getPalletList";
        const data = {
            status,
            pageNum,
            pageSize,
            createName
        };
        return axios.get(url, {params: data}).then((res: any) => {
            if (res.data && res.data.resultCode === "0") {
                return Promise.resolve(res.data.page);
            } else {
                Toast(res.data ? res.data.resultMsg : "网络异常");
                return Promise.reject(res.data);
            }
        });
    },
    plateExamine(id: string, examinetype = "", reason = "") {
        const url = "/shipplate/passOrReject2";
        const data = {id, examinetype, reason};
        return axios.get(url, {params: data}).then((res: any) => {
            if (res.data && res.data.resultCode === "0") {
                return Promise.resolve(res.data.data);
            } else {
                Toast(res.data ? res.data.resultMsg : "网络异常");
                return Promise.reject(res.data);
            }
        });
    },
    seletShipPages(pageNo = 1, pageSize = 10, shipMmsi = null, shipName = null, companyId = null, uid = null, minWeight = null, maxWeight = null) {
        const data = {
            pageNo: pageNo,
            pageSize: pageSize,
            shipMmsi: shipMmsi,
            shipName: shipName,
            companyId: companyId,
            uid: uid,
            minWeight: minWeight,
            maxWeight: maxWeight
        };
        const url = "/ship/seletShipPages";
        return axios.get(url, {params: data}).then((res: any) => {
            if (res.data && res.data.resultCode === "0") {
                return Promise.resolve(res.data.data);
            } else {
                Toast(res.data ? res.data.resultMsg : "网络异常");
                return Promise.reject(res.data);
            }
        });
    },
    getShipById(id: string) {
        const data = {
            shipId:id
        };
        const url = "/ship/getShipById";
        return axios.get(url, {params: data}).then((res: any) => {
            if (res.data && res.data.resultCode === "0") {
                return Promise.resolve(res.data.data);
            } else {
                Toast(res.data ? res.data.resultMsg : "网络异常");
                return Promise.reject(res.data);
            }
        });
    },
    selectListLastVoyageNumber(id: string) {
        const data = {
            shipMmsi:id
        };
        const url = "/ship/selectListLastVoyageNumber";
        return axios.get(url, {params: data}).then((res: any) => {
            if (res.data && res.data.resultCode === "0") {
                return Promise.resolve(res.data.data);
            } else {
                Toast(res.data ? res.data.resultMsg : "网络异常");
                return Promise.reject(res.data);
            }
        });
    },
};
export default ship;
