<template>
  <div class="about">
    <van-tabs v-model="active"  swipeable swipe-threshold = '6' sticky animated color="#4CD5A6" @change="tabChange" >
      <van-tab title="审核中" name="10">
        <plate-chk-list ref="child10" :code="active"></plate-chk-list>
      </van-tab>
      <van-tab title="已发布" name="1">
        <plate-chk-list ref="child1" :code="active"></plate-chk-list>
      </van-tab>
      <van-tab title="驳回" name="11">
        <plate-chk-list ref="child11" :code="active"></plate-chk-list>
      </van-tab>
      <van-tab title="重新审核" name="12">
        <plate-chk-list ref="child12" :code="active"></plate-chk-list>
      </van-tab>
      <van-tab title="已结束" name="3">
        <plate-chk-list ref="child3" :code="active"></plate-chk-list>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { Tab, Tabs } from "vant";
import PlateChkList from "../components/PlateChkList.vue";
export default {
  name: "PlateChk",
  data() {
    return {
      active: 10,
    };
  },
  created() {
    this.$store.commit("SET_APPINFO", { navTitle: "货盘审核" });
    this.loadRouter();
  },
  components: {
    VanTab: Tab,
    VanTabs: Tabs,
    PlateChkList
  },
  methods: {
    loadRouter() {
      console.log(this.$router.history);
    },
    tabChange(name, title) {
      if (name == 1 && this.$refs.child1) {
        this.$refs.child1.onRefresh(this.active);
      } else if (name == 10 && this.$refs.child10) {
        this.$refs.child10.onRefresh(this.active);
      } else if (name == 12 && this.$refs.child12) {
        this.$refs.child12.onRefresh(this.active);
      } else if (name == 3 && this.$refs.child3) {
        this.$refs.child3.onRefresh(this.active);
      } else if (name == 11 && this.$refs.child11) {
        this.$refs.child11.onRefresh(this.active);
      }
    }
  }
};
</script>
