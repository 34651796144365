import { render, staticRenderFns } from "./ShipRzDetail.vue?vue&type=template&id=34ec6f92&scoped=true&"
import script from "./ShipRzDetail.vue?vue&type=script&lang=js&"
export * from "./ShipRzDetail.vue?vue&type=script&lang=js&"
import style0 from "./ShipRzDetail.vue?vue&type=style&index=0&id=34ec6f92&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34ec6f92",
  null
  
)

export default component.exports