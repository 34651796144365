










































































































































































































































import {
  Empty,
  PullRefresh,
  Button,
  List,
  Cell,
  Divider,
  CellGroup,
  Field,
  Col,
  Row,
  Image as VanImage,
  ImagePreview,
  Search,
  Popup,
  Toast,
  Notify,
  Collapse,
  CollapseItem,
} from 'vant'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ship from '../api/ship'

@Component({
  name: 'ShipChkList',
  components: {
    VanField: Field,
    VanEmpty: Empty,
    VanPullRefresh: PullRefresh,
    VanList: List,
    VanCell: Cell,
    VanSearch: Search,
    VanDivider: Divider,
    VanCellGroup: CellGroup,
    VanImage: VanImage,
    VanButton: Button,
    VanCol: Col,
    VanRow: Row,
    VanPopup: Popup,
    VanCollapse: Collapse,
    VanCollapseItem: CollapseItem,
  },
})
export default class ShipChkList extends Vue {
  @Prop() private code!: number
  private isLoading = false
  private loading = false
  private finished = false
  private show = false
  private cannelVal = ''
  private shipName = ''
  private list = [] as any
  private activeNames = ['1']
  pageNo = 0
  shipId!: string
  companyindex = 0

  private onLoad(): void {
    this.pageNo++
    this.showCode(this.code)
  }

  private onSearch() {
    this.onRefresh(0)
  }

  private cannelAuthen(id: string, index: number): void {
    this.shipId = id
    this.cannelVal = ''
    this.companyindex = index
    // 审核拒绝
    // 填写拒绝理由
    this.show = true
  }

  private successRejectAuthen(id: BigInteger): void {
    Toast.loading({
      message: '加载中...',
      duration: 0,
      forbidClick: true,
    })
    ship.authenExamine(this.shipId, '0', this.cannelVal).then((res: any) => {
      Toast.clear()
      this.show = false
      this.onRefresh(this.code)
    })
  }

  private successCannelAuthen(id: string): void {
    Toast.loading({
      message: '加载中...',
      duration: 0,
      forbidClick: true,
    })
    ship.authenExamine(id, '4', this.cannelVal).then((res: any) => {
      Toast.clear()
      this.onRefresh(this.code)
    })
  }

  private successAuthen(id: BigInteger, index: number): void {
    // 审核通过
    this.companyindex = index
    Toast.loading({
      message: '加载中...',
      duration: 0,
      forbidClick: true,
    })
    ship.getSameShip(id).then((res: any) => {
      if (res != null && res.length > 0) {
        Toast.clear()
        Notify({type: 'warning', message: '此船已被认证，请先取消之前认证'})
      } else {
        ship
          .authenExamine(id, '1', '', this.list[index].createBy)
          .then((res: any) => {
            Toast.clear()
            this.onRefresh(this.code)
          })
      }
    })
  }

  private goRz(id: string): void {
    this.$router.push({
      path: '/shipRzChk',
      query: {
        shipId: id,
      },
    })
  }

  private goStand(id: string): void {
    this.$router.push({
      path: '/shipStandChk',
      query: {
        shipId: id,
      },
    })
  }

  private showImg(list: Array<string>, start = 0) {
    if (typeof list === 'string') {
      list = [list]
    }
    ImagePreview({
      images: list,
      startPosition: start,
      onClose() {
        // do something
      },
    })
  }

  private showCode(code: number): void {
    ship.shipPagesByStatus(code, this.pageNo, 20, this.shipName).then((res: any) => {
      this.list = this.list.concat(res)
      this.isLoading = false
      this.loading = false
      if (!res || res.length == 0) {
        this.finished = true
      }
    })
  }

  onRefresh(code: number): void {
    this.pageNo = 1
    this.finished = false
    this.list = []
    this.showCode(code || this.code)
  }
}
