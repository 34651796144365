import { render, staticRenderFns } from "./PlateChkList.vue?vue&type=template&id=665f3388&scoped=true&"
import script from "./PlateChkList.vue?vue&type=script&lang=ts&"
export * from "./PlateChkList.vue?vue&type=script&lang=ts&"
import style0 from "./PlateChkList.vue?vue&type=style&index=0&id=665f3388&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "665f3388",
  null
  
)

export default component.exports