<template>
  <div class="about">
    <van-divider
      :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
      >{{ itemName }}</van-divider
    >
    <van-divider>基础信息</van-divider>
    <van-cell-group v-for="(item, index) of itemLists" :key="index">
      <template v-for="(sitem, sindex) of item.list">
        <van-cell
          title-class="title-cls"
          :key="sindex"
          v-if="sitem.dataType != 'img'"
          :title="sitem.name1"
          :value="sitem.strValue"
        />
      </template>
    </van-cell-group>
    <van-divider>图片</van-divider>
    <template v-if="cardimgs != null && cardimgs.length > 0">
      <van-image
        v-for="(item, index) of cardimgs"
        :key="index"
        fit="cover"
        width="10rem"
        height="10rem"
        lazy-load
        @click="showImg(item.plist)"
        :src="item.value"
      />
    </template>
    <van-cell-group>
      <van-field
        v-model="scope"
        label="评分"
        v-if="status != '1' && stype == '2'"
        readonly
        autosize
        type="digit"
        input-align="right"
      />
      <van-field
        v-model="scope"
        label="评分"
        v-if="status == '1' && stype == '2'"
        autosize
        type="digit"
        input-align="right"
      />
      <van-field
        v-model="textarea"
        label="审核说明"
        v-if="
          (status != '1' && stype == '1') || (stype == '2' && status == '0')
        "
        readonly
        autosize
        type="textarea"
        input-align="right"
      />
      <van-field
        v-model="textarea"
        label="审核说明"
        v-else
        autosize
        type="textarea"
        input-align="right"
      />
    </van-cell-group>
    <van-row type="flex" justify="center" v-if="status == '1'">
      <van-col span="8">
        <van-button plain hairline type="warning" @click="updateStatus('2')"
          >审核拒绝</van-button
        ></van-col
      >
      <van-col span="8">
        <van-button plain hairline type="primary" @click="updateStatus('3')"
          >审核通过</van-button
        ></van-col
      >
    </van-row>
  </div>
</template>
<script>
import {
  Cell,
  Divider,
  CellGroup,
  Toast,
  Image as VanImage,
  ImagePreview,
  Button,
  Col,
  Notify,
  Row,
  Field
} from "vant";
import gtemplate from "../api/gtemplate";
import ship from "../api/ship";
export default {
  name: "ShipStandDetail",
  data() {
    return {
      objId: this.$route.query.objId,
      status: this.$route.query.status,
      typetpl: "shipstandard",
      textarea: "",
      scope: "",
      itemList: [],
      itemLists: [],
      form: [],
      cardimgs: [],
      fileSssList: [],
      itemName: this.$route.query.itemName,
      stype: this.$route.query.stype
    };
  },
  created() {
    this.$store.commit("SET_APPINFO", { navTitle: "船舶标准化审核" });
    this.loadData();
  },
  components: {
    VanCell: Cell,
    VanDivider: Divider,
    VanCellGroup: CellGroup,
    VanImage: VanImage,
    VanButton: Button,
    VanCol: Col,
    VanRow: Row,
    VanField: Field
  },
  methods: {
    showImg(list, start = 0) {
      if (typeof list === "string") {
        list = [list];
      }
      ImagePreview({
        images: list,
        startPosition: start,
        onClose() {
          // do something
        }
      });
    },
    loadData() {
      Toast.loading({
        message: "加载中...",
        duration: 0,
        forbidClick: true
      });
      gtemplate.getDetail(this.typetpl, this.objId).then(res => {
        Toast.clear();
        this.def(res);
      });
    },
    def(data) {
      console.log(data);
      if (data == null) {
        return;
      }
      // 根据数据获取循环， fileSssList form 集合赋值
      let index = 0;
      data.forEach(item => {
        if (item.subItemList != null) {
          item.subItemList.forEach(subitem => {
            if (subitem != null) {
              if (subitem.dataType == "img") {
                // cardimg
                let m = subitem.strValue.split("|");
                m.forEach(pic => {
                  let mdic = {
                    name: "图片",
                    value: pic,
                    plist: [pic]
                  };
                  this.cardimgs.push(mdic);
                });
              }
              this.itemList.push(subitem);
              this.setFormItem(subitem, index);
            }
          });
        }
        if (item.secondItemList != null) {
          item.secondItemList.forEach(seconditem => {
            if (seconditem != null) {
              seconditem.subItemList.forEach(subitem => {
                this.setFormItem(subitem, index);
              });
            }
          });
        }
        index++;
      });
      let sin = 0;
      this.itemLists = [];
      if (this.itemList != null) {
        this.itemList.forEach(item => {
          console.info(item);
          if (item != null) {
            if (sin == 0) {
              if (item.content != null && item.content.length > 0) {
                this.textarea = item.content;
              } else {
                this.textarea = "暂无";
              }
              if (item.score != null && item.score.length > 0) {
                this.scope = item.score;
              } else {
                this.scope = "0";
              }
            }
            if ((sin + 1) % 2 == 1) {
              let subDic = {};
              let subList = [];
              subList.push(item);
              subDic.list = subList;
              this.itemLists.push(subDic);
            } else {
              let kitem = this.itemLists[this.itemLists.length - 1];
              kitem.list.push(item);
            }
          }
          sin++;
        });
      }
      console.log("------------");
      console.log(this.itemLists);
    },
    setFormItem(item, index) {
      let nameitem = item.mdTplValueId || item.id;
      let itemId = item.id;
      if (item.dataType == "img") {
        this.$set(
          this.fileSssList,
          index,
          Object.assign({}, this.fileSssList[index], {
            [nameitem]: item.strValue
              ? item.strValue.split("|").map(sss => {
                  return { url: sss };
                })
              : []
          })
        );
      }
      this.$set(
        this.form,
        index,
        Object.assign({}, this.form[index], {
          [nameitem]: item.strValue ? item.strValue : ""
        })
      );
    },
    updateStatus(status) {
      if (status == "3" && this.stype == "2") {
        if (this.scope == null || this.scope.length == 0) {
          this.scope = "0"; //用户未输入，默认替换成0
        }
        if (parseInt(this.scope) < 0) {
          Notify({ type: "warning", message: "评分不能小于0" });
          return false;
        }
      }
      if (
        this.$route.query.objId == null ||
        this.$route.query.objId.length == 0
      ) {
        Notify({ type: "warning", message: "参数丢失，请重试！" });
        return false;
      }
      if (
        this.$route.query.shipId == null ||
        this.$route.query.shipId.length == 0
      ) {
        Notify({ type: "warning", message: "参数丢失，请重试！" });
        return false;
      }
      Toast.loading({
        message: "提交中...",
        duration: 0,
        forbidClick: true
      });
      ship
        .addShipStandard(
          this.$route.query.objId,
          status,
          this.textarea,
          this.$route.query.shipId,
          this.scope
        )
        .then(res => {
          Toast.clear();
          this.$router.go(-1);
        });
    }
  }
};
</script>
<style scoped lang="scss">
.title-cls {
  text-align: left;
}
</style>
